const BYSTRO_NOT_NUMERIC_FIELDS = ['pos', 'vcfPos', 'clinvarVcf.RS', 'id', 'gnomad.exomes.id', 'gnomad.genomes.id', 'clinvarVcf.id', 'refSeq.codonNumber', 'homozygotes', 'heterozygotes', 'missingGenos', 'clinvarVcf.ALLELEID', 'clinvarVcf.DBVARID', 'clinvarVcf.ORIGIN'];
const BYSTRO_LOCUS_FIELDS = ['id', 'chrom', 'pos', 'vcfPos', 'ref', "inputRef", 'alt', 'type', 'ac', 'an', 'sampleMaf', 'heterozygosity', 'homozygosity', 'missingness', 'discordant', 'trTv'];
const BYSTRO_SAMPLE_FIELDS = ['heterozygotes', 'homozygotes', 'missingGenos'];
const BYSTRO_MAIN_TRACK_KEYS = BYSTRO_LOCUS_FIELDS.concat(BYSTRO_SAMPLE_FIELDS);

const CLINVAR_VCF_ALLELE_ID_FIELDS = ['ALLELEID', 'id', 'RS'];

const POPGEN_TRACKS = ['gnomad.genomes', 'gnomad.exomes', 'dbSNP'];
const CLINICAL_TRACKS = ['clinvarVcf'];
const SCORE_TRACKS = ['cadd', 'caddIndel'];
const CADD_TRACK = 'cadd';
const CADD_INDEL_TRACK = 'caddIndel';
const REFSEQ_TRACK = 'refSeq';
const NEAREST_TSS_TRACK = 'nearestTss.refSeq';
const NEAREST_REFSEQ_TRACK = 'nearest.refSeq';
const CLINVAR_TRACK = 'clinvarVcf';

// Fields that may look numeric but are not
const NON_NUMERIC_FIELDS = {
  heterozygotes: true,
  homozygotes: true,
  missingGenos: true,
};

function looksLikeFloat(val) {
  var floatRegex = /^-?\d*(\.\d+)?([eE][-+]?\d+)?$/;
  if (!floatRegex.test(val)) {
    return false;
  }

  val = parseFloat(val);
  if (isNaN(val)) {
    return false;
  }

  return true;
}

function removeCommaAfterParentheses(input) {
  const tempProtected = [];
  const protect = str => {
    const placeholder = `<<${tempProtected.length}>>`;
    tempProtected.push(str);
    return placeholder;
  };

  // Protect quoted strings
  input = input.replace(/"[^"]*"/g, protect);

  // Remove commas after parentheses unless they follow a protected placeholder
  input = input.replace(/(\))(,+)(\s*)/g, (match, closeParen, comma, spacer) => {
    if (spacer.includes('<<')) {  // Check if the spacer contains a placeholder (indicating a protected quote)
      return closeParen + spacer + comma;  // Return the original spacing and comma
    } else {
      if (!spacer.length) {
        // check if the string ends after the comma, if so, don't add an unnecessary space
        if (input.indexOf(comma) + comma.length - 1 === input.length - 1) {
          spacer = '';
        } else {
          spacer = ' ';
        }
      }
      return closeParen + spacer;  // Remove the comma
    }
  });

  // Restore the protected parts
  tempProtected.forEach((str, index) => {
    input = input.replace(new RegExp(`<<${index}>>`, 'g'), str);
  });

  return input;
}

function normalizeQuotes(input) {
  // Replace curly double quotes “” with straight double quotes ""
  input = input.replace(/[\u201C\u201D]/g, '"');
  // Replace curly single quotes ‘’ with straight single quotes ''
  input = input.replace(/[\u2018\u2019]/g, "'");
  return input;
}

function wrapTermsWithExceptions(input) {
  const tempProtected = [];
  const protect = str => {
    const placeholder = `<<${tempProtected.length}>>`;
    tempProtected.push(str);
    return placeholder;
  };

  // Protect quoted strings
  input = input.replace(/"[^"]*"/g, protect);

  // Protect expressions recursively within (), {}, [], {], and [} without mixing
  function protectNested() {
    var regex = /\([^\(\)]*\)|\{[^\{\}]*\}|\[[^\[\]]*\]|\{[^\{\]]*\]|\[[^\[\}]*\}/g;
    while (input.match(regex)) {
      input = input.replace(regex, protect);
    }
  }
  protectNested();

  // Split input into tokens for processing
  let tokens = input.split(/\s+/).filter(Boolean);

  tokens = tokens.map(token => {
    // Check for leading operators followed by protected content
    if (/^[+\-]<<\d+>>$/.test(token)) {
      const index = parseInt(token.match(/<<(\d+)>>/)[1]);
      return token[0] + tempProtected[index]; // Prepend the operator to the protected content
    }

    // Return original value for protected placeholders
    if (token.match(/^<<\d+>>$/)) {
      return tempProtected[parseInt(token.slice(2, -2))];
    }

    // For logical operators AND and OR, do not wrap
    if (token === "&&" || token === "||" || token === 'AND' || token === 'OR' || token === 'NOT') {
      return token;
    }

    // Wrap standalone terms
    return `(${token})`;
  });

  // Replace nested placeholders in protected items
  function replaceNestedPlaceholders() {
    let replaced;
    do {
      replaced = false;
      /* jshint ignore:start */
      tempProtected.forEach((item, index) => {
        const newItem = item.replace(/<<(\d+)>>/g, (m, i) => {
          replaced = true;
          return tempProtected[i];
        });
        tempProtected[index] = newItem;
      });
      /* jshint ignore:end */
    } while (replaced);
  }
  replaceNestedPlaceholders();

  // Reconstruct the input
  input = tokens.join(' ');

  // Unprotect quotes and parentheses by replacing placeholders with original content
  tempProtected.forEach((str, index) => {
    input = input.replace(new RegExp(`<<${index}>>`, 'g'), str);
  });

  return input;
}

function transformFieldsWithDynamicArity(dataStructure, altField) {
  const calculateNumberOfPositions = () => {
    if (typeof altField === 'number') {
      return Math.abs(altField) <= 32 ? Math.abs(altField) : 32;
    }

    return altField.length >= 2 ? 2 : 1;
  };

  const positionsCount = calculateNumberOfPositions();

  // Calculate the maximum arity (number of items) for a given position across all fields
  const calculateMaxArityForPosition = (positionData) => {
    let maxArity = 0;

    // if the positionData values are arrays and not objects, set maxArity to 0
    if (Array.isArray(positionData)) {
      return positionData.length;
    }

    Object.values(positionData).forEach(field => {
      maxArity = Math.max(maxArity, field.length);
    });
    return maxArity;
  };

  // Transform the data for each position
  const transformPositionData = (positionData, keys) => {
    const maxArity = calculateMaxArityForPosition(positionData);
    const positionResult = [];

    for (let i = 0; i < maxArity; i++) {
      const itemInfo = {};
      /* jshint ignore:start */
      keys.forEach(key => {
        const value = positionData[key].length == 1 ? positionData[key][0] : positionData[key][i];
        itemInfo[key] = (Array.isArray(value) && value.length == 1) ? value[0] : value;
      });
      /* jshint ignore:end */
      positionResult.push(itemInfo);
    }

    return positionResult;
  };

  const transformPositionDataForNoKeyData = (positionData) => {
    const maxArity = calculateMaxArityForPosition(positionData);
    const positionResult = [];

    for (let i = 0; i < maxArity; i++) {
      const value = positionData.length == 1 ? positionData[0] : positionData[i];
      positionResult.push((Array.isArray(value) && value.length == 1) ? value[0] : value);
    }

    return positionResult;
  };

  let keys = null;
  if (!Array.isArray(dataStructure)) {
    keys = Object.keys(dataStructure);
  }

  // The final transformed structure for all positions
  const transformedData = [];

  // Iterate over positions, the outermost dimension
  for (let positionIndex = 0; positionIndex < positionsCount; positionIndex++) {
    // Extract data for the current position across all fields

    if (keys === null) {
      transformedData.push(transformPositionDataForNoKeyData(dataStructure.length == 1 ? dataStructure[0] : dataStructure[positionIndex]));
      continue;
    }

    const positionData = {};
    /* jshint ignore:start */
    keys.forEach(key => {
      positionData[key] = dataStructure[key].length == 1 ? dataStructure[key][0] : dataStructure[key][positionIndex];
    });
    /* jshint ignore:end */
    transformedData.push(transformPositionData(positionData, keys));
  }

  return transformedData;
}

function generateDesiredStructsOfArrays(document) {
  const result = {};

  // Function to transform an object into the desired format
  // Placeholder: Adjust this function based on how you want to transform your data
  const transformObject = (obj) => obj;

  // Recursive function to traverse the document
  // It separates objects based on whether they're nested and accumulates paths in dot notation
  const traverseAndTransform = (obj, currentPath = '') => {
    let hasNestedObjects = false;

    for (const [key, value] of Object.entries(obj)) {
      const newPath = currentPath ? `${currentPath}.${key}` : key;

      if (typeof value === 'object' && !Array.isArray(value)) {
        hasNestedObjects = true;
        traverseAndTransform(value, newPath);
      }
    }

    // If the object has no nested objects or is a leaf node, transform and add it to the result
    if (!hasNestedObjects || currentPath) {
      result[currentPath] = transformObject(obj);
    }
  };

  // Start the traversal from the document root
  traverseAndTransform(document);

  // Find any keys in the document not in the result, and add them back
  const allKeys = Object.keys(document);
  allKeys.forEach((key) => {
    if (!result[key]) {
      result[key] = document[key];
    }
  });

  // Post-process to remove entries where the value is nested within another entry
  // This handles cases like 'refSeq' containing 'refSeq.clinvar' directly
  Object.keys(result).forEach((key) => {
    const nestedKeys = Object.keys(result).filter((k) => k.startsWith(`${key}.`));
    nestedKeys.forEach((nestedKey) => {
      const subKey = nestedKey.substring(key.length + 1);
      delete result[key][subKey];
    });
  });

  return result;
}

function sortKeys(result, dropAlt = false) {
  let keys = Object.keys(result);

  if (dropAlt) {
    const altIndex = keys.indexOf("alt");

    if (altIndex > -1) {
      keys.splice(keys.indexOf("alt"), 1);
    }
  }

  keys = keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  // check if there is an id field, if so, make that the first entry
  if (keys.includes("id")) {
    keys.splice(keys.indexOf("id"), 1);
    keys.unshift("id");
  }

  return keys;
}

function hitToSortedArrayOfObjects(result, dropAlt = false) {
  let keys = Object.keys(result);


  if (dropAlt) {
    keys.splice(keys.indexOf("alt"), 1);
  }

  keys = keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  if (keys.includes("id")) {
    keys.splice(keys.indexOf("id"), 1);
    keys.unshift("id");
  }

  const newResult = [];
  keys.forEach(key => {
    newResult.push([key, result[key]]);
  });

  return newResult;
}

function sortTrackKeys(hit) {
  const keys = sortKeys(hit);

  const bystroMainTrackIdx = [];
  const keysToAdd = [];
  for (let i = 0; i < BYSTRO_MAIN_TRACK_KEYS.length; i++) {
    const idx = keys.indexOf(BYSTRO_MAIN_TRACK_KEYS[i]);
    if (idx > -1) {
      keys.splice(idx, 1);
      bystroMainTrackIdx.push(idx);
      keysToAdd.push(BYSTRO_MAIN_TRACK_KEYS[i]);
    }
  }

  return keysToAdd.concat(keys);
}

function tracksOfObjectsToTrackOfArrays(data, trackName = '') {
  // A recursive function to handle conversion
  function convertAndSort(obj, convertKey = '') {
    if (obj === null || obj === undefined) {
      return null;
    }

    if (typeof obj !== 'object') {
      if (!BYSTRO_NOT_NUMERIC_FIELDS.includes(convertKey)) {
        let num = obj;
        if (!NON_NUMERIC_FIELDS[convertKey] && looksLikeFloat(obj)) {
          num = parseFloat(obj);
        }

        if (!isNaN(num)) {
          if (num === 0) {
            return 0;
          }

          const absNum = Math.abs(num);
          if (absNum < 0.0001 || absNum > 1000000) {
            return parseFloat(num).toExponential(4);
          }

          // Truncate to 4 decimal places
          return parseFloat(num.toFixed(4));
        }
      }

      return obj;
    }

    // If obj is an array, map over it and convert each element
    if (Array.isArray(obj)) {
      return obj.map(element => {
        if (element === null || element === undefined) {
          return null;
        }
        // If the element is an object, convert it directly to [key, value] pairs
        if (typeof element === 'object' && !Array.isArray(element)) {

          const keys = sortKeys(element, true);

          if (keys.length == 0) {
            return [];
          }

          if (keys.length == 1) {
            return [keys[0], convertAndSort(element[keys[0]], convertKey ? convertKey + '.' + keys[0] : keys[0])];
          }

          return keys.map(key => [key, convertAndSort(element[key], convertKey ? convertKey + '.' + key : key)]);
        }
        // For other types of elements, or arrays, recursively process them
        return convertAndSort(element, convertKey);
      });
    }

    const keys = sortKeys(obj, true);

    if (keys.length == 0) {
      return [];
    }

    if (keys.length == 1) {
      return [keys[0], convertAndSort(obj[keys[0]], convertKey ? convertKey + '.' + keys[0] : keys[0])];
    }

    return keys.map(key => [key, convertAndSort(obj[key], convertKey ? convertKey + '.' + key : key)]);
  }

  return convertAndSort(data, trackName);
}

function fillQueryResultsObject(data) {
  const queryResultsUnique = [];

  data.hits.hits.forEach(rowResultObj => {
    const row = {};

    const flattenedData = generateDesiredStructsOfArrays(Object.assign({}, rowResultObj._source));
    for (let key in flattenedData) {
      // gnomad doesn't have any array values, only gnomad.genomes and gnomad.exomes, which are nested within gnomad,
      // so gnomad itself will become gnomad: {}, with "gnomad.genomes" and "gnomad.exomes" having the data
      if (Object.keys(flattenedData[key]).length == 0) {
        continue;
      }

      row[key] = transformFieldsWithDynamicArity(flattenedData[key], rowResultObj._source['alt'][0][0][0]);
    }

    queryResultsUnique.push(row);
  });

  return queryResultsUnique;
}

function allAreEmpty(arr) {
  return !arr || arr.every(val => val[1] === '' || val[1] === null || val[1] === undefined);
}

function parseLocusAndTransform(input) {
  // This regex matches the genomic data pattern anywhere in the string, potentially enclosed in parentheses.
  // It captures the surrounding parentheses, if present, as well as the genomic data.
  const pattern = /(\(?)(chr[\dXYM]+):(\d+):([ACTG]):([ACTG]|\+?[ACTG]+|\-?\d+)(\)?)/g;

  // This function will be used to transform each match.
  const transformMatch = (match, preParenthesis, chrom, pos, inputRef, alt, postParenthesis, offset, string) => {
    // Correctly escape + or - at the beginning of the alt field
    let transformedAlt = alt.replace(/^(\+|\-)/, '\\$1');

    // Determine if we should wrap the result in parentheses based on whether they were present around the original match
    let useParens = preParenthesis === '(' && postParenthesis === ')' ? '' : '(';
    let endParens = preParenthesis === '(' && postParenthesis === ')' ? '' : ')';
    let result = `${useParens}chrom:${chrom} pos:${pos} inputRef:${inputRef} alt:${transformedAlt}${endParens}`;

    // If the original match was part of a larger substring (e.g., leading space or nested parentheses), include that context in the replacement
    if (match.startsWith(' ') || match.startsWith('(')) {
      result = match[0] + result;
    }
    if (match.endsWith(')')) {
      result += ')';
    }
    return result;
  };

  // Replace each occurrence in the input string using the pattern and transformation logic.
  return input.replace(pattern, transformMatch);
}


function ElasticSearchController(
  $log,
  $window,
  $location,
  $http,
  $q,
  $timeout,
  $anchorScroll,
  $mdDialog,
  $document,
  _,
  SETTINGS,
  userProfile,
  searchFields,
  jStat,
  userTokens
) {
  this.customFilter = (field) => {
    if (this.searchFiltersText) {
      return field[0].toLowerCase().indexOf(this.searchFiltersText.toLowerCase()) !== -1;
    }

    return true;
  };

  this.profile = userProfile;
  this.BYSTRO_SAMPLE_FIELDS = BYSTRO_SAMPLE_FIELDS;
  this.BYSTRO_LOCUS_FIELDS = BYSTRO_LOCUS_FIELDS;
  this.BYSTRO_MAIN_TRACK_KEYS = BYSTRO_MAIN_TRACK_KEYS;
  this.POPGEN_TRACKS = POPGEN_TRACKS;
  this.CLINICAL_TRACKS = CLINICAL_TRACKS;
  this.SCORE_TRACKS = SCORE_TRACKS;
  this.CADD_INDEL_TRACK = CADD_INDEL_TRACK;
  this.CADD_TRACK = CADD_TRACK;
  this.CLINVAR_TRACK = CLINVAR_TRACK;
  this.REFSEQ_TRACK = REFSEQ_TRACK;
  this.NEAREST_TSS_TRACK = NEAREST_TSS_TRACK;
  this.NEAREST_REFSEQ_TRACK = NEAREST_REFSEQ_TRACK;

  this.normalizeQuotes = normalizeQuotes;
  this.wrapTermsWithExceptions = wrapTermsWithExceptions;
  this.removeCommaAfterParentheses = removeCommaAfterParentheses;
  this.transformFieldsWithDynamicArity = transformFieldsWithDynamicArity;
  this.fillQueryResultsObject = fillQueryResultsObject; //export for testing
  this.tracksOfObjectsToTrackOfArrays = tracksOfObjectsToTrackOfArrays; //export for testing
  this.parseLocusAndTransform = parseLocusAndTransform; //export for testing
  this.allAreEmpty = allAreEmpty;

  this.refSeqIndelPos = {};
  this.nearestIndelPos = {};

  this.filtersShown = true;

  this.showSampleList = {};

  // Not sure why cannot inject numeral using constant in config.js
  const { numeral } = $window;
  let fuzzy;

  this.isArray = Array.isArray;

  this.currentQuery = null;
  this.transformedQuery = null;
  this.lastCompletedQuery = null;
  this.currentQueryCompressed = null;
  this.toggledButtons = {};

  const _clearQueries = () => {
    this.transformedQuery = null;
    this.lastCompletedQuery = null;
    this.currentQuery = null;
    this.currentQueryCompressed = null;
    this.toggledButtons = {};
  };

  const _clearFilters = () => {
    this.filterThresholds = {};
    this.filters = [];
    this.filtersChecked = {};
    this.rangeFilters = {};
    this.rangeFiltersList = [];
    this.rangeQueriesLt = {};
    this.rangeQueriesGt = {};
  };

  this.didYouMean = {};
  this.didYouMeanOrder = [];

  this.fields = "";

  this.fetchedMapping = false;

  this.mapping = null;

  this.indexName = null;
  this.indexConfigFetched = null;
  this.type = null;

  this.results = [];
  this.resultsObj = [];

  this.allToggledAggregations = {};

  this.queryResultsAggregation = null;

  this.sortName = null;
  this.reverse = null;

  this.sortCounts = {};
  // this.keyboardShortcutFilter = keyboardShortcut;

  // TODO: get from result
  this.assembly = null;

  this.foundLastPage = false;

  // How much to search
  this.recordsToFetch = 10;
  // Where to start the search from
  this.fromRecord = 0;
  // Did we find the last record
  this.foundLastPage = false;
  // Let the user know what page we're on
  this.page = 0;
  this.pages = 0;

  // Let the view layer know when something has been searched AND no results found
  this.noResults = false;

  this.searching = false;

  this.expandAllCards = true;

  this.sortQueries = [];
  this.searchErr = "";

  // due to db versioning
  // TODO: better solution, based on the mapping we have
  this.exactName2Field = "refSeq.name2";

  // Todo: use a default list, or pass a sortable fields config hash, or
  // and maybe this is smarter, fetch the mapping?
  // These are keywords
  // These will be merged with raw fields and
  this.sortableFields = [];

  this.synonyms = null;
  this.synonymsLowerCase = null;

  // Includes the sub-mappings (different mappings of same field)
  // so wil include both (field1 and field1.exact)
  // allows to test whether exact value no matter what user enters
  this.allExactSearchFields = {};
  // the minimal representation, without the '.exact' field value
  this.allExactSearchFieldsMinimal = {};
  this.orderedExactSearchFieldsMinimal = [];

  // Newer mappings have a trTv field, that simplifies trTv calculations
  this.hasTrTvField = false;

  this.pipeline = [];

  let lowerCaseFields = {};
  let parentLowerCaseFields = {};
  // Records to fetch

  let sortModes = {};

  let explain = 0;

  this.fieldsToSearch = [];

  this.defaultSearchFields = [];

  this.allToggledAggregations = {};
  this.allSearchFields = {};

  this.parentFields = {};
  this.numericalFields = {};
  this.booleanFields = {};

  this.ranSetup = false;
  this.setupFailed = false;
  this.runningSetup = true;

  const _clearState = () => {
    this.ranSetup = false;
    this.setupFailed = false;
    this.runningSetup = true;

    this.didYouMean = {};
    this.didYouMeanOrder = [];
    this.showSampleList = {};

    this.fields = "";

    this.mapping = null;

    this.indexName = null;
    this.type = null;

    this.results = [];
    this.resultsObj = [];

    this.uniqueRefSeqTranscriptsConsequences = [];
    this.uniqueRefSeqTranscripts = [];

    this.uniqueNearestTssTranscripts = [];
    this.uniqueNearestTranscripts = [];

    this.showAll = {};

    this.allToggledAggregations = {};

    this.queryResultsAggregation = null;

    this.sortName = null;
    this.reverse = null;

    this.sortCounts = {};
    // this.keyboardShortcutFilter = keyboardShortcut;

    // TODO: get from result
    this.assembly = null;

    this.foundLastPage = false;

    // How much to search
    this.recordsToFetch = 10;
    // Where to start the search from
    this.fromRecord = 0;
    // Did we find the last record
    this.foundLastPage = false;
    // Let the user know what page we're on
    this.page = 0;
    this.pages = 0;

    // Let the view layer know when something has been searched AND no results found
    this.noResults = false;

    this.searching = false;

    this.expandAllCards = true;

    this.sortQueries = [];
    this.searchErr = "";

    // due to db versioning
    // TODO: better solution, based on the mapping we have
    this.exactName2Field = "refSeq.name2";

    // Todo: use a default list, or pass a sortable fields config hash, or
    // and maybe this is smarter, fetch the mapping?
    // These are keywords
    // These will be merged with raw fields and
    this.sortableFields = [];

    this.synonyms = null;
    this.synonymsLowerCase = null;

    // Includes the sub-mappings (different mappings of same field)
    // so wil include both (field1 and field1.exact)
    // allows to test whether exact value no matter what user enters
    this.allExactSearchFields = {};
    // the minimal representation, without the '.exact' field value
    this.allExactSearchFieldsMinimal = {};
    this.orderedExactSearchFieldsMinimal = [];
    this.searchFiltersText = '';

    _clearFilters();

    // Newer mappings have a trTv field, that simplifies trTv calculations
    this.hasTrTvField = false;

    this.pipeline = [];
    //
    lowerCaseFields = {};
    parentLowerCaseFields = {};
    // Records to fetch

    sortModes = {};

    explain = 0;

    this.fieldsToSearch = [];

    this.defaultSearchFields = [];

    this.allToggledAggregations = {};
    this.allSearchFields = {};

    this.parentFields = {};
    this.numericalFields = {};
    this.booleanFields = {};

    this.refSeqIndelPos = {};
    this.nearestIndelPos = {};
  };

  const _clearQueryState = () => {
    this.didYouMean = {};
    this.didYouMeanOrder = [];
    this.showSampleList = {};

    this.results = [];
    this.resultsObj = [];

    this.uniqueRefSeqTranscriptsConsequences = [];
    this.uniqueRefSeqTranscripts = [];

    this.uniqueNearestTssTranscripts = [];
    this.uniqueNearestTranscripts = [];

    this.showAll = {};

    this.foundLastPage = false;

    this.fromRecord = 0;

    // Let the user know what page we're on
    this.page = 0;
    this.pages = 0;

    // Let the view layer know when something has been searched AND no results found
    this.noResults = false;

    this.searching = false;

    this.searchErr = "";


    this.refSeqIndelPos = {};
    this.nearestIndelPos = {};
  };

  const defaultOperator = "AND";

  const settings = {
    // Lenient true completely breaks multi-field search
    // returns records when 0 matches
    lenient: true,
    phrase_slop: 5,
    // minimum_should_match: "0%",
    // auto_generate_phrase_queries: true,
    // split_on_whitespace: true,
    // multiple matchd clauses get ranked higher by 30%
    tie_breaker: 0.3
    // split_queries_on_whitespace: true
    // flags: 'ALL'
    // default_field: '_all',
  };

  // TODO: Get these from config file
  const valueDelimiter = " ; ";
  const positionDelimiter = " | ";
  const alleleDelimiter = " / ";
  const emptyFieldChar = "!";

  this.emptyFieldChar = emptyFieldChar;

  let queryCanceler = $q.defer();

  this.$onDestroy = () => {
    //cancel any in-progress query
    //avoids potential issues if we navigate away from component
    //then back, and re-query before previous query resolved
    //most apparent if http interceptor buffers during offline use & resolves
    //after connection re-established
    queryCanceler.resolve();
  };

  this.scrollTo = id => {
    $location.hash(id);
    $anchorScroll();
  };

  this.onPipelineUpdate = pipeline => {
    this.pipeline = [].concat(pipeline);
  };

  this.filter = (fieldName, val, exclude) => {
    let found = false;

    this.filters.forEach((iVal, idx) => {
      if (iVal[0] === fieldName && iVal[1] === val) {
        found = true;
        this.filters.splice(idx, 1);
      }
    });

    if (!found) {
      const hasSpace = typeof val === "string" && val.indexOf(" ") > -1;
      this.filters.push([fieldName, val, !!exclude, hasSpace]);
    }

    this.searchInput(this.transformedQuery, null, null, null, null, false, true);
  };

  let rangeTimeout;
  this.applyRangeQuery = (key, value, gte = false) => {
    if (value === null || value === undefined) {
      this.clearRangeQueries(key, gte, !gte);
      return;
    }

    this.rangeFilters = this.rangeFilters || {};
    this.rangeFilters[key] = this.rangeFilters[key] || {};

    if (gte) {
      this.rangeFilters[key].gte = value;
    } else {
      this.rangeFilters[key].lte = value;
    }

    this.rangeFiltersList = [];

    Object.keys(this.rangeFilters).forEach(key => this.rangeFiltersList.push([key, this.rangeFilters[key]]));

    if (rangeTimeout) {
      clearTimeout(rangeTimeout);
    }

    rangeTimeout = setTimeout(() => {
      this.searchInput(this.transformedQuery, null, null, null, null, false, true);
    }, 1000);
  };

  this.clearRangeQueries = (key = null, gte = false, lte = false) => {
    if (key !== null && key !== undefined) {
      if (this.rangeFilters.hasOwnProperty(key)) {

        if (gte) {
          if (this.rangeFilters[key].hasOwnProperty('gte')) {
            delete this.rangeFilters[key].gte;
          }
        }

        if (lte) {
          if (this.rangeFilters[key].hasOwnProperty('lte')) {
            delete this.rangeFilters[key].lte;
          }
        }

        if (!this.rangeFilters[key].hasOwnProperty('gte') && !this.rangeFilters[key].hasOwnProperty('lte')) {
          delete this.rangeFilters[key];
        }
      }

      this.rangeFiltersList = [];

      Object.keys(this.rangeFilters).forEach(key => this.rangeFiltersList.push([key, this.rangeFilters[key]]));
    } else {
      this.rangeFilters = {};
      this.rangeFiltersList = [];
    }

    if (rangeTimeout) {
      clearTimeout(rangeTimeout);
    }

    rangeTimeout = setTimeout(() => {
      this.searchInput(this.transformedQuery, null, null, null, null, false, true);
    }, 500);
  };

  this.searchByRangeQueryList = () => {
    this.rangeFilters = {};

    if (this.rangeFiltersList.length > 0) {
      // TODO //2024-03-28 Figure out why val is sometimes null
      const finalList = [];
      this.rangeFiltersList.forEach(val => {
        if (val[1] === null || val[1] === undefined) {
          $log.warn("null or undefined value in rangeFiltersList", JSON.stringify(this.rangeFiltersList), JSON.stringify(this.rangeFilters));
          return;
        }
        this.rangeFilters[val[0]] = val[1];

        if (val[1].gte) {
          this.rangeQueriesGt[val[0]] = val[1].gte;
        }

        if (val[1].lte) {
          this.rangeQueriesLt[val[0]] = val[1].lte;
        }

        finalList.push(val);
      });

      this.rangeFiltersList = finalList;
    }

    if (rangeTimeout) {
      clearTimeout(rangeTimeout);
    }

    rangeTimeout = setTimeout(() => {
      this.searchInput(this.transformedQuery, null, null, null, null, false, true);
    }, 500);
  };


  this.showSynonymDialog = ev =>
    $mdDialog.show({
      controller: function DialogController($scope, existingSynonyms) {

        if (existingSynonyms && Object.keys(existingSynonyms).length) {
          $scope.synonymsArray = [];

          Object.keys(existingSynonyms).forEach(name => {
            if (Array.isArray(existingSynonyms[name])) {
              $scope.synonymsArray.push({
                name,
                value: existingSynonyms[name].join("\n")
              });
            } else {
              $scope.synonymsArray.push({
                name,
                value: existingSynonyms[name]
              });
            }
          });
        } else {
          $scope.synonymsArray = [
            {
              name: "",
              value: ""
            }
          ];
        }

        $scope.addSynonymToArray = function () {
          $scope.synonymsArray.push({
            name: "",
            value: ""
          });
        };

        $scope.removeSynonym = function () {
          $scope.synonymsArray.pop();
        };

        $scope.attachCasesAndControls = function () {
          const synonyms = {};

          $scope.synonymsArray.forEach(synonym => {
            if (synonym.name.match(/^s+$/)) {
              return;
            }

            let synonymValue;
            if (typeof synonym.value === "string") {
              if (synonym.value.match(/^\s+$/)) {
                return;
              }

              synonymValue = synonym.value
                .split(/[\r\n]/)
                .map(val => val.trim());
            } else {
              synonymValue = synonym.value;
            }

            synonyms[synonym.name.trim()] = synonymValue;
          });

          $mdDialog.hide(synonyms);
        };

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      },
      templateUrl: "jobs/results/search/jobs.results.search.synonyms.tpl.html",
      parent: angular.element($document[0].body),
      locals: {
        existingSynonyms: this.synonyms
      },
      targetEvent: ev,
      clickOutsideToClose: true
    });

  this.setCaseControls = () => {
    this.showSynonymDialog().then(synonyms => {
      if (!Object.keys(synonyms).length) {
        return;
      }

      this.synonyms = Object.assign({}, synonyms);

      this.synonymsLowerCase = {};

      Object.keys(this.synonyms).forEach(name => {
        this.synonymsLowerCase[name.toLowerCase()] = this.synonyms[name];
      });

      $http
        .post(
          `${SETTINGS.apiEndpoint}jobs/${this.jobResource._id}/addSynonyms`,
          {
            synonyms: this.synonyms
          }
        )
        .then(() => this.searchInput(this.query));
    });
  };

  this.showCard = ($event, index) => {
    // Don't show the full card if the user just wanted to go to the link
    if ($event.target.href) {
      return;
    }

    this.showFullCard = this.results[index];
  };

  this.isExonic = result => result["refSeq.siteType"].includes("exonic");

  this.getAllSortCounts = () => {
    if (this.sortQueries.length === 0) {
      this.sortCounts = {};
    }

    this.sortQueries.forEach(object => {
      const sortName = Object.keys(object)[0];

      if (object[sortName].order === "asc") {
        this.sortCounts[sortName] = 2;
      } else if (object[sortName].order === "desc") {
        this.sortCounts[sortName] = 1;
      }
    });
  };

  // TODO: combine all NCBI link makes, differe only by path
  this.makeRefSeqLink = searchFields.makeRefSeqLink;

  this.makeDbSnpLink = searchFields.makeDbSnpLink;

  //strangely enough, if this is named this.sort, it won't work
  //in angular's template
  this.sortResults = sortName => {
    this.page = 0;
    this.foundLastPage = false;

    let hasSort;
    let sortNameCount = 0;

    const origSortName = sortName;

    //scripted fields cannot be submitted in an array...
    //or be named I believe, at least not by the same name
    //as the real sort field ... figure this out
    if (sortName === "chrom") {
      sortName = "_script";
    }

    if (this.sortQueries.length > 0) {
      this.sortQueries.forEach((object, index) => {
        if (object.hasOwnProperty(sortName)) {
          hasSort = true;

          if (object[sortName].order === "asc") {
            this.sortQueries.splice(index, 1);
          } else if (object[sortName].order === "desc") {
            object[sortName].order = "asc";
            sortNameCount = 2;
          }
        }
      });
    }

    if (!hasSort) {
      const sort = {};

      // TODO: make less fragile
      if (origSortName === "chrom") {
        sort[sortName] = {
          script: {
            source:
              "def val = doc['chrom'].value.substring(3); if(val == 'x' || val == 'X'){return 23;}else if(val == 'y' || val == 'Y'){return 24;} else if(val == 'm' || val == 'M' || val == 'mt' || val == 'MT'){return 25;} else { return Integer.parseInt(val) }"
          },
          type: "number"
        };
      } else {
        sort[sortName] = {};
      }

      sort[sortName].order = "desc";

      if (sortModes[sortName]) {
        sort[sortName].mode = sortModes[sortName];
      }

      this.sortQueries.push(sort);

      sortNameCount = 1;
    }

    this.sortCounts[origSortName] = sortNameCount;

    this.fromRecord = 0;
    this.page = 0;
    this.foundLastPage = false;
    this.searchInput(this.transformedQuery, false, false, false, false, false, true);

    return sortNameCount;
  };

  this.makePhenotypeIDlinks = searchFields.makePhenotypeIDlinks;

  const transversions = {
    AT: 1,
    TA: 1,
    GT: 1,
    TG: 1,
    AC: 1,
    CA: 1,
    CG: 1,
    GC: 1
  };

  const transitions = {
    AG: 1,
    GA: 1,
    TC: 1,
    CT: 1
  };

  const _makeDidYouMeanString = (
    transformedQuery,
    didYouMean,
    didYouMeanOrder
  ) => {
    let newQuery = transformedQuery;

    didYouMeanOrder.forEach(queryPart => {
      const re = new RegExp(`(${addslashes(queryPart)})`, "gi");
      newQuery = newQuery.replace(
        re,
        () => (didYouMean[queryPart] ? didYouMean[queryPart][0][1] : "")
      );
    });

    return newQuery;
  };

  this.updateSize = (size = 10) => {
    this.recordsToFetch = size;

    this.searchInput(this.transformedQuery, null, null, null, 0);
  };

  this.updateQueryString = (newQuery, append) => {
    if (append) {
      this.query = `${newQuery} ${this.query}`;
    } else {
      this.query = newQuery;
    }

    this.searchInput(this.query);
    this.onSuggested({
      queryWithSuggestion: this.query
    });
  };

  this.getClinvarSize = (end, start) => {
    const startArr = start.split(valueDelimiter);
    const endArr = end.split(valueDelimiter);
    return startArr
      .map((val, idx) => (endArr[idx] - val).toLocaleString())
      .join(` bp ${valueDelimiter}`);
  };

  const _formFilterBody = (boolQuery, filters = {}) => {
    const rCheckedFilters = {};

    filters.forEach(val => {
      const term = {};
      term[val[0]] = val[1];

      if (!rCheckedFilters[val[0]]) {
        rCheckedFilters[val[0]] = {};
      }

      if (!rCheckedFilters[val[0]][val[1]]) {
        rCheckedFilters[val[0]][val[1]] = {};
      }

      if (rCheckedFilters[val[0]][val[1]][val[2]] === undefined) {
        rCheckedFilters[val[0]][val[1]][val[2]] = true;
      }

      // Exclude or include; true === must_not (exclude)
      if (val[2]) {
        if (!boolQuery.must_not) {
          boolQuery.must_not = [];
        }
        boolQuery.must_not.push({
          term
        });
      } else {
        if (!boolQuery.filter) {
          boolQuery.filter = [];
        }
        boolQuery.filter.push({
          term
        });
      }
    });

    return rCheckedFilters;
  };

  this.selectQuickQuery = query => {
    this.onCanned({ query });
  };

  let searchTimeout;
  this.searchInput = (
    newQuery,
    previous,
    next,
    toPage,
    from,
    scroll = true,
    hasFilter = false,
    filterAgg = null
  ) => {
    this.showFullCard = false;
    this.searching = true;

    let trulyNew =
      hasFilter ||
      previous ||
      next ||
      (toPage || toPage === 0) ||
      (from || from === 0);

    if (newQuery) {
      this.didYouMeanString = null;
      this.didYouMean = {};
      this.didYouMeanOrder = [];

      const [wrappedQuery, query] = this._makeQueryFromString(newQuery) || "*";

      this.transformedQuery = wrappedQuery;
      trulyNew = trulyNew || this.transformedQuery !== this.lastCompletedQuery;

      if (trulyNew) {
        this.currentQuery = newQuery;

        this.onTransform({
          // Don't transform the query in the url...synonym expansion can yield
          // huge urls that lead to webserver errors
          transformedQuery: this.transformedQuery,
          suggestions: this.didYouMean
        });

        if (this.didYouMeanOrder.length) {
          this.didYouMeanString = _makeDidYouMeanString(
            query,
            this.didYouMean,
            this.didYouMeanOrder
          );
        }
      }
    }

    // Each time we trigger an aggregation, the query is submitted, so process
    // them serially when possible (i.e when filterAgg is submitted to searchInput)
    const aggregations = _formAggregationsBody(
      filterAgg || this.allToggledAggregations
    );

    [this.page, this.fromRecord, this.foundLastPage] = _paginate(
      this.page,
      this.fromRecord,
      this.foundLastPage,
      this.recordsToFetch,
      previous,
      next
    );

    const body = {
      size: this.recordsToFetch,
      from: this.fromRecord,
      query: {
        bool: {
          must: {}
        }
      },
      track_total_hits: true
    };

    //Regression in 5.5, stops treating these as match__all
    const trimmedOriginalQuery = newQuery && newQuery.trim();
    const matchStarWithSpacesPattern = /^\(\s*\*\s*\)$/;

    if (!trimmedOriginalQuery || trimmedOriginalQuery === "*" || matchStarWithSpacesPattern.test(trimmedOriginalQuery)) {
      body.query.bool.must = {
        match_all: {}
      };
    } else {
      body.query.bool.must = {
        query_string: {
          default_operator: defaultOperator,
          query: this.transformedQuery,
          fields: this.defaultSearchFields,
          quote_field_suffix: ".exact",
        }
      };

      Object.assign(body.query.bool.must.query_string, settings);
    }

    if (Object.keys(this.filters).length) {
      this.filtersChecked = _formFilterBody(
        body.query.bool, this.filters
      );
    } else {
      this.filtersChecked = {};
    }

    if (Object.keys(this.rangeFilters || {}).length) {
      body.query.bool.filter = body.query.bool.filter || [];
      for (let key in this.rangeFilters) {
        body.query.bool.filter.push({
          range: {
            [key]: this.rangeFilters[key]
          }
        });
      }
    }

    if (this.sortQueries.length) {
      body.sort = this.sortQueries;
    }

    this.queryBodyWithoutAggregations = Object.assign({}, body);
    delete this.queryBodyWithoutAggregations.from;
    delete this.queryBodyWithoutAggregations.size;

    if (Object.keys(aggregations).length) {
      body.aggregations = aggregations;
    }

    this.update({
      q: this.currentQuery,
      sort: this.sortQueries.length ? JSON.stringify(this.sortQueries) : null,
      size: this.recordsToFetch,
      from: this.fromRecord,
      compressed: this.currentQueryCompressed
    });

    queryCanceler.resolve();
    queryCanceler = $q.defer();

    //if this is just an aggregation, set size to 0, speed up, allow elastic to cache
    if (!trulyNew) {
      body.size = 0;
      delete body.from;
    }

    //Don't query model to avoid sending the entire job state (including results)
    $http
      .post(
        `${SETTINGS.apiEndpoint}jobs/${this.jobResource._id}/search`,
        {
          searchBody: body
        },
        {
          timeout: queryCanceler.promise
        }
      )
      .then(response => {
        if (explain) {
          $log.debug("Explain response:", response);
          return;
        }

        const data = response.data.body;

        if (data['_shards'].total !== data['_shards'].successful) {
          throw ({ data: "shards_missing", statusText: "We couldn't find all expected parts of this index. Probably best to re-index", status: 255 });
        }

        if (searchTimeout) {
          $timeout.cancel(searchTimeout);
        }

        searchTimeout = $timeout(() => {
          this.took = data.took / 1000;
          this.searchErr = "";
          // Can have aggregation only query
          this.addAggregations(data, filterAgg);
          if (!trulyNew) {
            this.searching = false;
            return;
          }
          this.results = [];
          this.resultsObj = [];

          // create a set of refSeq tracks that are unique in terms of consequence, for display by default
          this.uniqueRefSeqTranscripts = [];
          this.uniqueRefSeqTranscriptsConsequences = [];

          this.uniqueNearestTssTranscripts = [];
          this.uniqueNearestTranscripts = [];

          this.showAll = {};


          this.refSeqIndelPos = {};
          this.nearestIndelPos = {};

          this.hits = data.hits.total.value;
          if (data && data.hits && data.hits.hits.length) {
            // Recombine clinvarVcf records,
            // Unfortunately, the VCF dataset is incomplete, so that we can not rely on arity alone.
            // For instance for https://www.ncbi.nlm.nih.gov/clinvar/variation/235/?oq=235&m=NM_000398.7(CYB5R3):c.173G%3EA%20(p.Arg58Gln)
            // While there are CLNDN Methemoglobinemia, type I, "not provided" (which we treat as null), and "CYB5R3-related condition",
            // these 3 correspond to CLNSIGCONF "pathogenic(1)", "likely pathogenic(1)", "likely pathogenic(1)" which is
            // missing from their vcf file. Instead they provide:
            // 22      42631431        235     C       T       .       .       AF_EXAC=0.00010;ALLELEID=15274;CLNDISDB=.|MedGen:C3661900|MedGen:C2749559;CLNDN=CYB5R3-related_condition|not_provided|Methemoglobinemia,_type_I;CLNHGVS=NC_000022.11:g.42631431C>T;CLNREVSTAT=criteria_provided,_multiple_submitters,_no_conflicts;CLNSIG=Likely_pathogenic;CLNVC=single_nucleotide_variant;CLNVCSO=SO:0001483;CLNVI=ClinGen:CA114069|OMIM:613213.0002|UniProtKB:P00387#VAR_004619;GENEINFO=CYB5R3:1727;MC=SO:0001583|missense_variant;ORIGIN=1;RS=121965007
            // Methemoglobinemia, type I
            // So unfortunately with clinvar, arity of fields in the 2nd dimension (the value dimension) is not sufficient to gauge 
            // which fields go together.
            // Instead, we will flatten the 2nd dimension, so that when correspondance exists, it will be visible to the user
            // but we will not auto-group records, as we do with other datasets.
            data.hits.hits.forEach((hit, index) => {

              this.refSeqIndelPos[index] = 0;
              this.nearestIndelPos[index] = 0;

              if (hit._source[CLINVAR_TRACK]) {

                // VCF tracks are exact hit, so we will look only at the first entry in the outer array
                const numAlleles = Math.max.apply(null, CLINVAR_VCF_ALLELE_ID_FIELDS.map(key => hit._source[CLINVAR_TRACK][key][0].length));

                if (numAlleles == 1) {
                  for (let key of Object.keys(hit._source[CLINVAR_TRACK])) {
                    if (hit._source[CLINVAR_TRACK][key][0].length > 1) {
                      // flatten the value dimension (2nd dimension; the first is the position dimension)
                      hit._source[CLINVAR_TRACK][key][0] = [hit._source[CLINVAR_TRACK][key][0].flat().filter(val => val !== null)];
                    }
                  }
                }
              }
            });

            this.noResults = false;

            //Data must be after; because could be aggregation only
            this.data = data;
            if (this.fromRecord + this.recordsToFetch >= data.hits.total.value) {
              this.foundLastPage = true;
            }

            this.pages = Math.ceil(data.hits.total.value / this.recordsToFetch);

            this.resultsObj = fillQueryResultsObject(data);

            this.results = this.resultsObj.map(row => {
              const res = {};

              for (let trackName in row) {
                res[trackName] = tracksOfObjectsToTrackOfArrays(row[trackName], trackName);
              }

              return res;
            });

            this.resultsObj.forEach((row, rowIdx) => {
              const refSeqTranscripts = [];
              const refSeqUniqueConsequences = [];

              const refSeqTrack = row[REFSEQ_TRACK];

              refSeqTrack.forEach((posData, posIdx) => {
                const consequencesMap = new Map(); // Map to store each consequence and its transcripts

                // First pass: accumulate transcripts for each consequence
                posData.forEach((transcript, transcriptIdx) => {
                  const consequence = this.transcriptToConsequence(transcript);
                  if (!consequencesMap.has(consequence)) {
                    consequencesMap.set(consequence, {
                      transcripts: [],
                      hasCanonical: false,
                      canonicalTranscript: null,
                    });
                  }

                  const consequenceInfo = consequencesMap.get(consequence);
                  consequenceInfo.transcripts.push([transcriptIdx + 1, this.results[rowIdx][REFSEQ_TRACK][posIdx][transcriptIdx]]);

                  if (transcript.hasOwnProperty('isCanonical') && (transcript.isCanonical === "true" || transcript.isCanonical === true)) {
                    consequenceInfo.hasCanonical = true;
                    consequenceInfo.canonicalTranscript = [transcriptIdx + 1, this.results[rowIdx][REFSEQ_TRACK][posIdx][transcriptIdx]];
                  }
                });

                // Second pass: decide which transcript to show for each consequence
                const uniqueRefSeqTranscriptsByConsequence = [];
                consequencesMap.forEach((value, key) => {
                  if (value.hasCanonical) {
                    // If there is a canonical transcript, use it
                    uniqueRefSeqTranscriptsByConsequence.push([
                      value.canonicalTranscript[0],
                      key,
                      value.canonicalTranscript[1]
                    ]);
                  } else {
                    // Otherwise, use the first transcript for this consequence
                    uniqueRefSeqTranscriptsByConsequence.push([
                      value.transcripts[0][0],
                      key,
                      value.transcripts[0][1]
                    ]);
                  }
                });

                refSeqUniqueConsequences.push(Array.from(consequencesMap.keys()));
                refSeqTranscripts.push(uniqueRefSeqTranscriptsByConsequence);
              });

              this.uniqueRefSeqTranscriptsConsequences.push(refSeqUniqueConsequences);
              this.uniqueRefSeqTranscripts.push(refSeqTranscripts);

              const nearestTssTrack = row[NEAREST_TSS_TRACK];
              const nearestTrack = row[NEAREST_REFSEQ_TRACK];

              const nearestTssUnique = [];
              nearestTssTrack.forEach((posData, posIdx) => {
                const consequenceSet = new Set();
                const uniqueConsequences = [];

                posData.forEach((transcript, transcriptIdx) => {
                  const consequence = JSON.stringify([transcript['dist'], transcript['name2']]);

                  if (!consequenceSet.has(consequence)) {
                    consequenceSet.add(consequence);
                    uniqueConsequences.push([transcriptIdx + 1, this.results[rowIdx][NEAREST_TSS_TRACK][posIdx][transcriptIdx]]);
                  }
                });

                nearestTssUnique.push(uniqueConsequences);
              });

              this.uniqueNearestTssTranscripts.push(nearestTssUnique);

              const nearestUnique = [];
              nearestTrack.forEach((posData, posIdx) => {
                const consequenceSet = new Set();
                const uniqueConsequences = [];

                posData.forEach((transcript, transcriptIdx) => {
                  const consequence = JSON.stringify([transcript['dist'], transcript['name2']]);

                  if (!consequenceSet.has(consequence)) {
                    consequenceSet.add(consequence);
                    uniqueConsequences.push([transcriptIdx + 1, this.results[rowIdx][NEAREST_REFSEQ_TRACK][posIdx][transcriptIdx]]);
                  }
                });

                nearestUnique.push(uniqueConsequences);
              });

              this.uniqueNearestTranscripts.push(nearestUnique);
            });

            this.chi2crit = jStat.chisquare.inv(1 - 0.05 / this.hits, 1);
            this.alpha = 0.05 / this.hits;
          } else {
            this.noResults = true;
            this.queryResultsAggregation = null;
            this.data = null;
            this.chi2crit = null;
            this.alpha = null;
          }
          this.lastCompletedQuery = this.transformedQuery;
          this.searching = false;
          searchTimeout = null;
          if (scroll) {
            $anchorScroll();
          }
        }, 16);
      })
      .catch(errResponse => {
        $log.error(errResponse);

        if (errResponse.xhrStatus === "abort") {
          // Aborted
          return;
        }

        _clearQueryState();

        if (explain) {
          $log.debug("Explain response:", errResponse);
          return;
        }

        if (errResponse.status === -1) {
          this.searchErr = "Connection error";
        } else {
          this.searchErr = errResponse.data || errResponse.statusText;
        }

        if (errResponse.data === "index_not_found_exception") {
          this.onMissing();
        }

        if (errResponse.data === "shards_missing") {
          this.onMissing({ type: "shards_missing" });
        }

        if (newQuery) {
          this.aggregations = [];
        }

        this.queryResultsAggregation = null;
        this.noResults = true;

        this.searching = false;
        this.lastCompletedQuery = null;

        if (scroll) {
          $anchorScroll();
        }
      });
  };

  function _paginate(
    page,
    fromRecord,
    foundLastPage,
    recordsToFetch,
    previous = false,
    next = false
  ) {
    let tPage = page;
    let fromR = fromRecord;
    let last = foundLastPage;

    if (previous) {
      tPage -= 1;
      fromR = tPage === 0 ? 0 : tPage * recordsToFetch;
      last = false;
    } else if (next) {
      tPage += 1;
      fromR = tPage === 0 ? 0 : tPage * recordsToFetch;
    }

    return [tPage, fromR, last];
  }

  function _formAggregationsBody(aggsVal = []) {
    const aggs = {};

    if (Object.keys(aggsVal).length) {
      Object.keys(aggsVal).forEach(agg => {
        // TODO: Simplify
        if (agg === "compoundHet" || agg === "homHetRatio") {
          aggs.hetCount = {
            terms: {
              size: aggsVal[agg][0],
              //could use params to tell fields
              field: aggsVal[agg][1][0]
            }
          };

          aggs.homCount = {
            terms: {
              size: aggsVal[agg][0],
              //could use params to tell fields
              field: aggsVal[agg][1][1]
            }
          };
        } else if (agg === "variantHoms") {
          aggs.variantHoms = {
            "terms": {
              "size": aggsVal[agg][0],
              "field": aggsVal[agg][1],
            },
            "aggs": {
              "homs": {
                "sum": {
                  "script": {
                    "source": "doc['homozygotes'].length"
                  },
                },
              }
            }
          };
        } else if (agg === "compoundBases") {
          // the old calculation for trTv
          aggs.compoundBases = {
            terms: {
              // There are only 12 possible combinations, so size of 12 works here
              size: aggsVal[agg][0],
              //could use params to tell fields
              //could use values to get a specific index
              //.value gives first index
              script: `if( doc['alt'].values.size() === 1 && (doc['alt'].value === 'A' ||
              doc['alt'].value === 'C' || doc['alt'].value === 'T' || doc['alt'].value === 'G')) {
                doc['ref'].value + doc['alt'].value
              }`
            }
          };
        } else {
          const types = aggsVal[agg][2] || ["terms"];

          types.forEach(type => {
            const newAgg = {};

            if (type !== "terms") {
              newAgg[type] = {
                field: aggsVal[agg][1]
              };
            } else {
              newAgg[type] = {
                size: aggsVal[agg][0],
                field: aggsVal[agg][1]
              };
            }

            if (type === "terms") {
              aggs[agg] = {};
              aggs[agg][type] = newAgg[type];
            } else {
              aggs[agg + "_" + type] = newAgg;
            }
          });
        }
      });
    }

    return aggs;
  }

  this.addAggregations = data => {
    if (!this.queryResultsAggregation) {
      this.queryResultsAggregation = {};
    }

    if (data.aggregations) {
      Object.assign(this.queryResultsAggregation, data.aggregations);

      if (data.aggregations.compoundBases || data.aggregations.trTv) {
        this.queryResultsAggregation.transitions = 0;
        this.queryResultsAggregation.transversions = 0;
        this.queryResultsAggregation.trTvRatio = Infinity;

        if (data.aggregations.compoundBases) {
          let tr = 0;
          let tv = 0;

          data.aggregations.compoundBases.buckets.forEach(val => {
            if (transitions.hasOwnProperty(val.key.toUpperCase())) {
              tr += val.doc_count;
            } else if (transversions.hasOwnProperty(val.key.toUpperCase())) {
              tv += val.doc_count;
            }
          });

          this.queryResultsAggregation.transitions = tr;
          this.queryResultsAggregation.transversions = tv;
        } else {
          data.aggregations.trTv.buckets.forEach(val => {
            if (val.key === 1) {
              this.queryResultsAggregation.transitions = val.doc_count;
            } else if (val.key === 2) {
              this.queryResultsAggregation.transversions = val.doc_count;
            }
          });
        }

        if (this.queryResultsAggregation.transversions > 0) {
          this.queryResultsAggregation.trTvRatio =
            (this.queryResultsAggregation.transitions || 0) /
            this.queryResultsAggregation.transversions;
        }
      }

      if (!(data.aggregations.homCount && data.aggregations.hetCount)) {
        return;
      }

      //TODO: use a better system here. If homCount set we are calculating samples hom/het ratio
      if (this.allToggledAggregations["compoundHet"]) {
        const sampleCounts = {};
        let totalHits = 0;
        data.aggregations.homCount.buckets.forEach(val => {
          if (!sampleCounts[val.key]) {
            sampleCounts[val.key] = val.doc_count * 2;
          } else {
            sampleCounts[val.key] += val.doc_count * 2;
          }
        });

        data.aggregations.hetCount.buckets.forEach(val => {
          if (!sampleCounts[val.key]) {
            sampleCounts[val.key] = val.doc_count;
          } else {
            sampleCounts[val.key] += val.doc_count;
          }
          totalHits += val.doc_count;
        });

        let cases = 0;
        let caseHits = 0;
        let controlHits = 0;
        let compoundCases = 0;
        let controls = 0;
        let compoundControls = 0;
        const totalCases =
          this.synonymsLowerCase &&
          this.synonymsLowerCase.cases &&
          this.synonymsLowerCase.cases.length;
        const totalControls =
          this.synonymsLowerCase &&
          this.synonymsLowerCase.controls &&
          this.synonymsLowerCase.controls.length;

        if (totalCases) {
          this.casesMap = {};
          this.synonymsLowerCase.cases.forEach(val => {
            this.casesMap[val] = 1;
          });
        }

        if (totalControls) {
          this.controlsMap = {};
          this.synonymsLowerCase.controls.forEach(val => {
            this.controlsMap[val] = 1;
          });
        }

        Object.keys(sampleCounts).forEach(sample => {
          if (totalCases || totalControls) {
            if (totalCases && this.casesMap[sample]) {
              cases++;
              caseHits += sampleCounts[sample];

              if (sampleCounts[sample] > 1) {
                compoundCases++;
              }
            } else if (totalControls && this.controlsMap[sample]) {
              controls++;

              controlHits += sampleCounts[sample];

              if (sampleCounts[sample] > 1) {
                compoundControls++;
              }
            }
          }
        });

        this.queryResultsAggregation.compoundHets = [];

        // sort in descending order by number of alleles
        Object.keys(sampleCounts)
          .sort((a, b) => sampleCounts[b] - sampleCounts[a])
          .forEach(sample => {
            this.queryResultsAggregation.compoundHets.push([
              sample,
              sampleCounts[sample]
            ]);
          });

        if (cases > 0 || controls > 0) {
          this.queryResultsAggregation.caseAlleles = caseHits || 0;
          this.queryResultsAggregation.controlAlleles = controlHits || 0;
          this.queryResultsAggregation.caseControlAlleleProp =
            caseHits / controlHits;
          this.queryResultsAggregation.totalAlleles = totalHits;
          this.queryResultsAggregation.cases = cases || 0;
          this.queryResultsAggregation.compoundCases = compoundCases || 0;
          this.queryResultsAggregation.controls = controls || 0;
          this.queryResultsAggregation.compoundControls = compoundControls || 0;
          this.queryResultsAggregation.totalCases = totalCases || 0;
          this.queryResultsAggregation.totalControls = totalControls || 0;
          this.queryResultsAggregation.totalCasesAndControls =
            totalControls + totalCases || 0;
        }
      }

      if (this.allToggledAggregations["homHetRatio"]) {
        const homHetRatio = [];
        const homCount = {};

        const all = [];
        data.aggregations.homCount.buckets.forEach(val => {
          homCount[val.key] = Number(val.doc_count);
        });

        data.aggregations.hetCount.buckets.forEach(val => {
          const ratio = Number(val.doc_count) / (homCount[val.key] || 0);
          homHetRatio.push([val.key, ratio]);
          all.push(ratio);
        });

        let mean = 0;
        let sd = 0;

        if (all.length) {
          homHetRatio.sort((a, b) => b[1] - a[1]);

          mean = all.reduce((sum, value) => sum + value, 0) / all.length;
          sd = Math.pow(
            all.reduce((sum, value) => sum + Math.pow(value - mean, 2)) /
            (all.length - 1),
            0.5
          );
        }

        this.queryResultsAggregation.homHetRatio = {
          ratio: homHetRatio,
          mean,
          sd
        };
      }
    }
  };

  // take a sample list in form [[sampleName, value1, valueN, ...]] and saves to file
  this.downloadSamples = (samples = [], name = "samples.txt") => {
    const out = [];

    samples.forEach(sampleInfo => {
      const info = sampleInfo.join("\t");

      out.push(info);
    });

    const str = out.join("\n");

    writeFile(str, name);
  };

  this.downloadVariantHoms = (buckets = [], name = "samples.txt") => {
    const str = buckets.map(bucket => `${bucket.key}\t${bucket.homs.value}`).join("\n");

    writeFile(str, name);
  };

  function writeFile(str, name) {
    const element = $document[0].createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(str)
    );
    element.setAttribute("download", name);
    element.target = "_self"; // may be needed for firefox
    element.style.display = "none";
    $document[0].body.appendChild(element);

    element.click();

    $document[0].body.removeChild(element);
  }

  this.toggleAnyAggregation = (name, size = 100) => {
    if (this.allToggledAggregations[name]) {
      delete this.allToggledAggregations[name];
      return;
    }

    // get the field name of the corresponding type: "keyword" field, which is the only that ES can perform terms aggregation on
    const eName =
      this.allExactSearchFields[name] ||
      ((name in this.numericalFields || name in this.booleanFields) && name);

    if (!eName) {
      $log.error("field not found in exact fields", name);
      return;
    }

    const type = ["terms"];

    if (
      name in this.numericalFields &&
      name !== "trTv"
    ) {
      type[0] = "stats";
    }

    // Last position of allToggledAggregations holds the exact version of the submitted name
    // Allows interface to not worry about presence of ".exact"
    // The aggregation results will be held under [name] rather than [eName]
    // Can result in duplicate aggregations with unexpected consequences
    this.allToggledAggregations[name] = [size, eName, type];

    const agg = {};
    agg[name] = this.allToggledAggregations[name];

    this.searchInput(this.transformedQuery, null, null, null, null, false, false, agg);
  };

  // A special aggregation; for compound hets
  // Mimics the toggleAnyAggregation return values
  this.toggleCompoundHetAggregation = () => {
    if (this.allToggledAggregations["compoundHet"]) {
      delete this.allToggledAggregations["compoundHet"];
      return;
    }

    if (!this.numSamples) {
      $log.warn(
        "Tried to toggle compoundHetAggregation with 0 samples in annotation"
      );
      return;
    }

    const eNameHet = this.allExactSearchFields["heterozygotes"];
    const eNameHom = this.allExactSearchFields["homozygotes"];

    this.allToggledAggregations["compoundHet"] = [
      this.numSamples,
      [eNameHet, eNameHom]
    ];

    this.searchInput(this.transformedQuery, null, null, null, null, false, false, {
      compoundHet: this.allToggledAggregations["compoundHet"]
    });
  };

  this.toggleHomHetRatioAggregation = () => {
    if (this.allToggledAggregations["homHetRatio"]) {
      delete this.allToggledAggregations["homHetRatio"];
      return;
    }

    if (!this.numSamples) {
      $log.warn(
        "Tried to toggle toggleHomHetRatioAggregation with 0 samples in annotation"
      );
      return;
    }

    const eNameHet = this.allExactSearchFields["heterozygotes"];
    const eNameHom = this.allExactSearchFields["homozygotes"];

    this.allToggledAggregations["homHetRatio"] = [
      this.numSamples,
      [eNameHet, eNameHom]
    ];

    this.searchInput(this.transformedQuery, null, null, null, null, false, false, {
      homHetRatio: this.allToggledAggregations["homHetRatio"]
    });
  };

  let setupCanceller;
  this.setupSearchIndex = () => {
    const currentJobID = this.jobResource._id;
    this.ranSetup = false;
    this.setupFailed = false;
    this.runningSetup = true;
    this.onReady({ ready: false });

    if (setupCanceller) {
      setupCanceller.abort();
    }

    $log.debug("Setting up search index for job: ", currentJobID);

    setupCanceller = new AbortController();

    return fetch(`/api/jobs/${currentJobID}/indexConfig`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userTokens.accessToken}`
      },
      signal: setupCanceller.signal
    }).then(response => {
      if (!response.ok) {
        throw new Error("index config not found");
      }

      return response.json();
    })
      .then(indexConfig => {
        Object.keys(indexConfig.mappings.properties).forEach(field => {
          searchFields.getMapping(
            indexConfig.mappings.properties[field],
            field,
            this.allSearchFields,
            this.allExactSearchFields,
            this.allExactSearchFieldsMinimal,
            this.parentFields,
            this.numericalFields,
            this.booleanFields
          );
        });

        Object.keys(this.numericalFields).forEach(field => {
          if (field === "trTv") {
            this.hasTrTvField = true;
          }
        });

        this.mapping.forEach(key => {
          if (key in this.allExactSearchFieldsMinimal) {
            this.orderedExactSearchFieldsMinimal.push([
              key,
              this.allExactSearchFieldsMinimal[key]
            ]);
          } else if (
            key in this.numericalFields ||
            key in this.booleanFields
          ) {
            this.orderedExactSearchFieldsMinimal.push([key, key]);
          }
        });

        let fields = [];

        Object.keys(this.allSearchFields).forEach(val => {
          if (searchFields.defaultFieldsNotSearched[val]) {
            // next;
            return;
          }

          fields.push(val);
        });

        const numericalFieldsSet = new Set(Object.keys(this.numericalFields).concat(Object.keys(this.booleanFields)).map(val => val.toLowerCase()));

        if (fields) {
          fields = fields.filter(
            field => !numericalFieldsSet.has(field.toLowerCase())
          );

          // Clinvar fields are not matched explicitly and include large CNVs that may bear
          // little relevance to the mutations in the user's dataset, so we will make them opt-in
          fields = fields.filter(
            field => !field.toLowerCase().startsWith("refseq.clinvar")
          );

          this.defaultSearchFields = fields;
        }

        // default fields include chrom, which is not numeric, but cast to be
        // Concat will make the result single copy (so can order by placing some items in the first array being
        // concatenated on)
        this.sortableFields = [].concat(
          ["chrom", "pos"],
          Object.keys(this.numericalFields).sort()
        );

        sortModes = indexConfig.sort || {};

        // Unique: http://stackoverflow.com/questions/1960473/unique-values-in-an-array
        this.sortableFields = this.sortableFields.filter((val, index, self) => {
          return self.indexOf(val) === index;
        });

        lowerCaseFields = {};
        Object.keys(this.allSearchFields).forEach(val => {
          lowerCaseFields[val.toLowerCase()] = val;
        });

        parentLowerCaseFields = {};
        Object.keys(this.parentFields).forEach(val => {
          parentLowerCaseFields[val.toLowerCase()] = val;
        });

        fuzzy = $window.FuzzySet(
          [].concat(
            Object.keys(lowerCaseFields),
            Object.keys(parentLowerCaseFields)
          ),
          true,
          3,
          8
        );

        //We default to auto trTv; but don't trigger a query, because we don't yet have one
        this.toggleTrTvAggregation(true);

        // Exclude exact fields from the default search fields
        const allSearchFieldsBesidesExact = Object.keys(this.allSearchFields).filter(item => !item.endsWith(".exact"));

        this.onReady({ ready: true, allSearchFields: allSearchFieldsBesidesExact });
        this.fetchedMapping = true;

        if (this.query) {
          this.searchInput(this.query);
        }

        // We should only have ranSetup completed when we have successfully fetched
        // this way, on subsequent queries, setup will be retried if there was a prior failure
        this.ranSetup = true;
        this.setupFailed = false;
      })
      .catch(error => {
        if (error.name !== "AbortError") {
          this.setupFailed = error;
        }
      }).finally(() => {
        this.runningSetup = false;
      });
  };

  this.toggleVariantHomsAggregation = noQuery => {
    if (this.allToggledAggregations["variantHoms"]) {
      delete this.allToggledAggregations["variantHoms"];
      return;
    }

    const agg = {};
    this.allToggledAggregations["variantHoms"] = [10e6, "gnomad.genomes.id"];
    agg.variantHoms = this.allToggledAggregations["variantHoms"];

    if (!noQuery) {
      this.searchInput(this.transformedQuery, null, null, null, null, false, false, agg);
    }
  };

  this.toggleTrTvAggregation = noQuery => {
    if (this.allToggledAggregations["trTv"]) {
      delete this.allToggledAggregations["trTv"];
      return;
    }

    if (this.allToggledAggregations["compoundBases"]) {
      delete this.allToggledAggregations["compoundBases"];
      return;
    }

    const agg = {};
    if (!this.hasTrTvField) {
      this.allToggledAggregations["compoundBases"] = [12];
      agg.compoundBases = this.allToggledAggregations["compoundBases"];
    } else {
      this.allToggledAggregations["trTv"] = [3, "trTv"];
      agg.trTv = this.allToggledAggregations["trTv"];
    }

    if (!noQuery) {
      this.searchInput(this.transformedQuery, null, null, null, null, false, false, agg);
    }
  };

  this._init = () => {
    _clearQueries();
    _clearState();

    // copy the array
    this.mapping = this.jobResource.search.fieldNames.slice(
      0
    );

    // Will ALWAYS give results for any darned query
    // TODO: have a boolean type category in search config file, exclude all boolean fields

    this.indexName = this.jobResource.search.indexName;
    this.type = this.jobResource.search.indexType;

    this.assembly = this.jobResource.assembly;

    // TODO: split into separate function / service
    this.synonyms = this.jobResource.search.synonyms;

    this.synonymsLowerCase = {};

    if (this.synonyms) {
      Object.keys(this.synonyms).forEach(name => {
        this.synonymsLowerCase[name.toLowerCase()] = this.synonyms[name];
      });
    }

    const currentStatus = this.jobResource.search.activeSubmission.state;

    if (currentStatus !== "completed") {
      alert("Search index not ready, please wait for it to complete");
    }

    // Technically we don't need to check if this.ranSetup
    // but just in case, because I am not certain that 
    // this._init will run before search is triggered (which will also run setupSearchIndex)
    if (!this.ranSetup) {
      this.setupSearchIndex();
    }
  };

  this.$onChanges = changedObjects => {
    if (changedObjects.jobResource) {

      // If we previously had a job and it was completed, and the new job is the same as the old job
      // in terms of completion state, don't run setup again
      if (changedObjects.jobResource.previousValue &&
        changedObjects.jobResource.previousValue._id == changedObjects.jobResource.currentValue._id && // unncessary I think that at this point this.jobResource == changedObjects.jobResource.currentValue, but just in case
        changedObjects.jobResource.previousValue.search.activeSubmission.state === 'completed' &&
        changedObjects.jobResource.currentValue.search.activeSubmission.state === 'completed' && this.ranSetup) {
        return;
      }

      $log.debug("jobResource changed, re-running search component initialization", changedObjects.jobResource.currentValue);

      this._init();
    }

    if (changedObjects.size) {
      if (changedObjects.size.currentValue) {
        this.recordsToFetch = parseInt(changedObjects.size.currentValue, 10);
      }
    }

    if (changedObjects.sort) {
      if (changedObjects.sort.currentValue) {
        this.sortQueries = JSON.parse(changedObjects.sort.currentValue);
        this.getAllSortCounts();
      }
    }

    if (changedObjects.from) {
      if (changedObjects.from.currentValue) {
        this.fromRecord = parseInt(changedObjects.from.currentValue, 10);
        this.page = this.fromRecord / this.recordsToFetch;
      }
    }

    // Must come last, because modifies other properties
    if (changedObjects.query) {
      const runQuery = () => {
        // We previously had a query, so this is a brand new search
        if (this.transformedQuery) {
          this.noResults = false;
          this.foundLastPage = false;
          this.fromRecord = 0;
          this.page = 0;
        }

        this.searchInput(changedObjects.query.currentValue);
      };

      if (!this.ranSetup) {
        if (this.jobResource.isIndexCompleted()) {
          this.setupSearchIndex().then(runQuery);
        } else {
          $log.warn('search index not ready, attempting anyway');
          this.setupSearchIndex().then(runQuery);
        }
      } else {
        runQuery();
      }
    }
  };


  this.parseFieldAndTransform = (input) => {
    const pattern = /(?<=^|\s|\()([\w.]+)(\\{0,1}\*){0,1}(\s*[:><=])/g;

    // This function will be used to transform each match.
    const transformMatch = (match, fieldName, maybePeriodStar) => {
      // Either the user gave us a field name, or some random string
      let lcField = fieldName.toLowerCase();
      let star = maybePeriodStar;

      if (lcField.charAt(fieldName.length - 1) === ".") {
        lcField = lcField.replace(/\.$/, "");
      }

      let updatedFieldName;

      if (parentLowerCaseFields[lcField]) {
        // Here, you could update `lcField` as needed or set `star = true` if required.
        lcField = parentLowerCaseFields[lcField];
      } else {
        updatedFieldName =
          lcField === "_exists_" ? lcField : lowerCaseFields[lcField];

        if (!updatedFieldName) {
          if (this.didYouMean[lcField] === undefined) {
            if (fuzzy) {
              const suggestion = fuzzy.get(lcField);

              if (suggestion) {
                this.didYouMean[lcField] = suggestion;
              }
            }
          }

          if (this.didYouMeanOrder.indexOf(lcField) === -1) {
            this.didYouMeanOrder.push(lcField);
          }

          // If it's not a real field name, the colon is part of the string (or the user gave us a wrong query)
          return match;
        }
      }

      if (this.didYouMean[lcField]) {
        // this.didYouMean[updatedField] = this.didYouMean[fieldName];
        delete this.didYouMean[lcField];
        this.didYouMeanOrder.splice(this.didYouMeanOrder.indexOf(lcField), 1);
      }

      // Escape the star, as required by the elastic query dsl
      return `${updatedFieldName || lcField}${star ? ".\\*" : ""}:`;
    };

    // Replace each occurrence in the input string using the pattern and transformation logic.
    return input.replace(pattern, transformMatch);
  };

  function addslashes(str) {
    return (str + "").replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0");
  }

  // If the user supplied something like "cadd > 20, convert that to cadd:>20,
  // which is elasticSearch compatible"
  const _scoreRegex = (
    match,
    fieldName,
    colon,
    infix,
    number,
    count,
    original
  ) => {
    if (!colon && !infix) {
      return match;
    }

    const lcField = fieldName.toLowerCase();

    if (lcField === "maf") {
      return (
        "(" +
        _scoreRegex(
          match,
          "gnomad.genomes.af",
          colon,
          infix,
          number,
          count,
          original
        ) +
        " || " +
        _scoreRegex(
          match,
          "gnomad.exomes.af",
          colon,
          infix,
          number,
          count,
          original
        ) +
        ")"
      );
    }

    if (this.numericalFields[lowerCaseFields[lcField]]) {
      if (infix) {
        if (infix === "==" || infix === "=") {
          infix = "";
        } else if (infix === "=>") {
          infix = ">=";
        } else if (infix === "=<") {
          infix = "<=";
        }
      }

      return `${lowerCaseFields[lcField]}:${infix || ""}${_formatNumber(
        number
      )}`;
    }

    if (!this.didYouMean[lcField]) {
      if (fuzzy) {
        this.didYouMean[lcField] = fuzzy.get(lcField);
      }

      if (!this.didYouMean[lcField]) {
        delete this.didYouMean[lcField];
      } else {
        this.didYouMeanOrder.push(lcField);
      }
    }

    return match;
  };

  const _scoreRangeRegex = (
    match,
    fieldName,
    optionalBracket,
    firstNumber,
    infix,
    secondNumber,
    optionalBracket2,
    count,
    original
  ) => {
    let lcField = fieldName.toLowerCase();

    if (lcField === "maf") {
      return (
        "(" +
        _scoreRangeRegex(
          match,
          "gnomad.genomes.af",
          optionalBracket,
          firstNumber,
          infix,
          secondNumber,
          optionalBracket2,
          count,
          original
        ) +
        " || " +
        _scoreRangeRegex(
          match,
          "gnomad.exomes.af",
          optionalBracket,
          firstNumber,
          infix,
          secondNumber,
          optionalBracket2,
          count,
          original
        ) +
        ")"
      );
    }

    if (!this.didYouMean[lcField]) {
      if (fuzzy) {
        this.didYouMean[lcField] = fuzzy.get(lcField);
      }

      if (!this.didYouMean[lcField]) {
        delete this.didYouMean[lcField];
      } else {
        this.didYouMeanOrder.push(lcField);
      }
    }

    return match;
  };

  this._makeQueryFromString = (userQuery) => {
    explain = 0;
    let query = userQuery;

    this.didYouMean = {};
    this.didYouMeanOrder = [];

    if (query.indexOf("--explain") > -1) {
      query = query.replace(/--explain/, "");
      explain = 1;
    }

    // TODO: improve this
    if (this.synonyms) {
      const synonyms = Object.keys(this.synonyms).sort(
        (a, b) => b.length - a.length
      );

      for (const synonym of synonyms) {
        const val = this.synonyms[synonym];

        query = query.replace(new RegExp(synonym, 'g'), () => {
          if (Array.isArray(val)) {
            return `( ${val.join(" || ")} )`;
          }

          return val;
        });
      }
    }

    // TODO: make chrom stuff case indpendent
    query = query.replace(/\b(chr)\s*:(\S+)/g, (match, chr, value) => {
      if (value.indexOf("chr") > -1) {
        return `chrom:${value}`;
      }

      return `chrom:chr${value}`;
    });

    query = parseLocusAndTransform(query);

    query = query.replace(
      /\b(chr[\w_]+)\s*:(\s*\[)?\s*([\de,\.+-]+)(\s*\-\s*|\s*TO\s*|\s*[\.]{2,4}\s*)([\de,\.+-]+)(\s*\])?/g,
      _chrScoreRangeRegex
    );
    query = query.replace(
      /\b([\.\w]+):(\s*\[)?\s*([\de,\.+-]+)(\s*\-\s*|\s*TO\s*|\s*[\.]{2,4}\s*)([\de,\.+-]+)(\s*\])?/g,
      _scoreRangeRegex
    );
    query = query.replace(
      /\b(chr[\w_]+)\s*([:>=<]{1,3})\s*([\de,\.+-]+)/g,
      _chrScoreRegex
    );
    query = query.replace(
      /\b([\.\w]+)\s*(:{0,1})\s*([>=<]{0,2})\s*([\de,\.+-]+)/g,
      _scoreRegex
    );

    query = query.replace(
      /([\(]+)?(doesn\'t\s+|doesnt\s+|not\s+|\!\s{0,1}|\-\s{0,1})?(no|not|in|without|have|with|has|contains|contain|_exists_|_missing_|missing|exists|including|include|includes|exclude|excludes|excluding)?(\\\:|\:|\s*)([\.\w]+)([\)]+)?(\S+)?/gi,
      (
        match,
        oParenLeft,
        oNegator,
        existsMatch,
        colonMatch,
        fieldMatch,
        oParenRight,
        oTherStuff,
        full
      ) => {
        const fieldName =
          lowerCaseFields[fieldMatch.toLowerCase()] ||
          parentLowerCaseFields[fieldMatch.toLowerCase()];

        if (!fieldName || oTherStuff) {
          return match;
        }

        let negator;

        if (oNegator) {
          negator = oNegator.toLowerCase().trim();

          if (
            negator === "not" ||
            negator === "doesn't" ||
            negator === "doesnt" ||
            negator === "!" ||
            negator === "-"
          ) {
            negator = "-";
          } else {
            negator = null;
          }
        }

        let exists;
        existsMatch = existsMatch ? existsMatch.toLowerCase() : null;

        if (!existsMatch) {
          if (negator) {
            exists = "_exists_";
          } else {
            // nothing
            return match;
          }
        } else if (
          existsMatch === "_missing_" ||
          existsMatch === "missing" ||
          existsMatch === "exclude" ||
          existsMatch === "excludes" ||
          existsMatch === "excluding" ||
          existsMatch === "without" ||
          existsMatch === "not" ||
          existsMatch === "no"
        ) {
          if (negator) {
            exists = "_exists_";
            negator = null;
          } else {
            exists = "-_exists_";
          }
        } else {
          exists = "_exists_";
        }

        let newParens = existsMatch != "_exists_";

        return `${oParenLeft || ""}${newParens ? "(" : ""}${negator ||
          ""}${exists}:${fieldName}${newParens ? ")" : ""}${oParenRight || ""}`;
      }
    );

    // the _fieldRegex must come after anything that we want transformed to a specific field
    // like samples above
    // Modifies this.didYouMean
    query = this.parseFieldAndTransform(query);

    query = query.replace(
      /\b(lof|\"loss\sof\sfunction\"|loss\sof\sfunction"|loss-of-function)\b/gi,
      (match, lof, full) =>
        `(${lof} || stopGain || stopLoss || spliceDonor || spliceAcceptor || indel-frameshift)`
    );

    // Elasticsearch hates non-alphanumeric after precedence operator
    // Not sure what best solution here is; for now stripping commas because refseq descriptions
    // often contain a comma after a parenthesis
    query = this.normalizeQuotes(query);
    query = this.removeCommaAfterParentheses(query);

    const wrappedQuery = this.wrapTermsWithExceptions(query);
    return [wrappedQuery, query];
  };

  this.transcriptToConsequence = transcript => {
    if (!transcript) {
      return '';
    }

    if (transcript['siteType']) {
      return _getOutcome(
        transcript['siteType'],
        transcript['codonNumber'],
        transcript['refAminoAcid'],
        transcript['altAminoAcid'],
        transcript['exonicAlleleFunction']
      );
    }
  };

  this.geneTrackToConsequences = trackTranscripts => {
    if (!trackTranscripts) {
      return 'intergenic';
    }

    const consequences = trackTranscripts.map(transcript => this.transcriptToConsequence(transcript));

    // get unique set in order of transcript
    const uniqueConsequences = {};
    consequences.map(val => {
      uniqueConsequences[val] = 1;
    });

    return consequences.filter(val => {
      if (uniqueConsequences[val]) {
        delete uniqueConsequences[val];
        return true;
      }

      return false;
    });
  };

  function _getOutcome(siteType, codonNum, refAA, altAA, exonicAlleleFunc) {
    switch (siteType) {
      case "exonic":
        // This case should never happen, but does, see
        // IL23R
        // chr1 : 67,672,592
        // in gnomad (3 base insertion)

        if (refAA && codonNum !== undefined && altAA) {
          return `${refAA}${codonNum}${altAA || "-"}`;
        }

        return exonicAlleleFunc || siteType;
      default:
        return siteType;
    }
  }

  function _formatNumber(num) {
    num = num.trim();

    num = num.replace(/e(\d+)/g, (match, gr1) => {
      return `e+${gr1}`;
    });

    num = numeral(num).value();
    //escape a leading - sign, if it is not already escaped
    num = `${num}`.replace(/(^-)(?!\s)/, "\\-");

    return num;
  }

  function _chrScoreRangeRegex(
    match,
    chr,
    optionalBracket,
    firstNumber,
    infix,
    secondNumber,
    optionalBracket2,
    count,
    original
  ) {
    if (chr.toLowerCase().indexOf("chrom") > -1) {
      return match;
    }

    return `(chrom:${chr} pos:[${firstNumber} TO ${secondNumber}${optionalBracket2 ||
      "]"})`;
  }

  function _chrScoreRegex(match, chr, infix, position) {
    $log.debug("chrScoreRegex got", arguments);
    if (chr.toLowerCase().indexOf("chrom") > -1) {
      return match;
    }

    let noColonInfix = infix.replace(/:/g, "");

    if (noColonInfix.indexOf(">") === -1 && noColonInfix.indexOf("<") === -1) {
      noColonInfix = noColonInfix.replace(/=/g, "");
    } else if (noColonInfix === "=>") {
      noColonInfix = ">=";
    } else if (noColonInfix === "=<") {
      noColonInfix = "<=";
    }

    if (position.indexOf(",") > -1) {
      position = position.replace(/,/g, "");
    }

    return `(chrom:${chr} pos:${noColonInfix}${position})`;
  }
}

angular
  .module("sq.jobs.results.search.component", [
    "sq.jobs.results.search.refSeq.component",
    "sq.jobs.download.directive",
    "sq.jobs.results.search.pipelines.component",
    "sq.jobs.results.search.save.component",
    "sq.jobs.search.fields.service",
    "sq.jobs.results.search.fullCard.component",
    "sq.user.profile.service",
    "sq.user.auth.tokens"
  ])
  .filter("keyboardShortcut", $window => {
    return str => {
      if (!str) {
        return;
      }

      const keys = str.split("-");
      const isOSX = /Mac OS X/.test($window.navigator.userAgent);

      const seperator = !isOSX || keys.length > 2 ? "+" : "";

      const abbreviations = {
        M: isOSX ? "⌘" : "Ctrl",
        A: isOSX ? "Option" : "Alt",
        S: "Shift"
      };

      return keys
        .map((key, index) => {
          const last = index === keys.length - 1;
          return last ? key : abbreviations[key];
        })
        .join(seperator);
    };
  })
  .component("sqSearch", {
    bindings: {
      jobResource: "<",
      numSamples: "<",
      query: "<",
      sort: "<",
      size: "<",
      from: "<",
      update: "&",
      onCanned: '&',
      onTransform: "&",
      onSuggested: "&",
      onMissing: "&",
      onReady: "&",
    }, // isolate scope
    templateUrl: "jobs/results/search/jobs.results.search.tpl.html",
    controller: ElasticSearchController
  });